import React from "react"
import {Link} from "gatsby"
import Layout from "../components/layout"


const IndexPage = () => {
  return (
    <Layout>
      <main className="row">
        <div className="col-12  m-auto py-5">
          <ul>
            <li><Link to="/aviso_privacidad">Aviso de privacidad</Link></li>
            <li><Link to="/politica_envios">Pol&iacute;tica de env&iacute;os</Link></li>
            <li><Link to="/politica_cambios_devoluciones">Pol&iacute;tica de cambios y devoluciones</Link></li>
            <li><Link to="/terminos_condiciones">T&eacute;rminos y condiciones</Link></li>
          </ul>
        </div>
      </main>
    </Layout>
  )
}

export default IndexPage

export const Head = () => <title>Home Page</title>
